body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;



@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

.logo {
  font-family: 'Poppins', sans-serif;
  color: #1E3A8A; /* Blue color */
  font-weight: 600; /* Bold */
  font-size: 1.5rem; /* Adjust as needed */
}


.slick-arrow {
  z-index: 1;
  background: #000; /* Poți schimba culoarea */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.75; /* Poți schimba opacitatea */
  transition: opacity 0.3s;
}

.slick-arrow:hover {
  opacity: 1;
}

.slick-prev, .slick-next {
  font-size: 0;
  color: #fff;
}

.slick-prev:before, .slick-next:before {
  font-size: 20px;
  line-height: 1;
  color: #fff; /* Poți schimba culoarea */
}

.slick-prev {
  left: -40px; /* Ajustează poziția săgeții */
}

.slick-next {
  right: -40px; /* Ajustează poziția săgeții */
}
