
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

.logo {
  font-family: 'Poppins', sans-serif;
  color: #1E3A8A; /* Blue color */
  font-weight: 600; /* Bold */
  font-size: 1.5rem; /* Adjust as needed */
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Adaugă stiluri personalizate pentru dropdown-ul de pe mobil */
.ant-dropdown {
  border-radius: 12px;
  overflow: hidden;
}

.ant-dropdown-menu {
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.ant-dropdown-menu-item {
  padding: 10px 20px;
}

.ant-dropdown-menu-item:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.slick-prev,
.slick-next {
  width: 30px;
  height: 30px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  z-index: 1;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.slick-prev {
  left: 1px;
}

.slick-next {
  right: 1px;
}

.slick-prev:before,
.slick-next:before {
  font-size: 20px;
  color: white;
}

.slick-prev:hover, .slick-next:hover {
  background-color: rgba(0, 0, 0, 0.75);
}


.red-x {
  color: red;
  font-size: 16px; /* Ajustează dimensiunea iconiței după preferință */
}

.green-check {
  color: green;
  font-size: 16px; /* Ajustează dimensiunea iconiței după preferință */
}